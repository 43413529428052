import { $fetch } from "ofetch";
import { useCsrf } from "./composables.js";
import { defineNuxtPlugin } from "#app";
export default defineNuxtPlugin(() => {
  const csrfFetch = $fetch.create({
    onRequest({ options }) {
      const { csrf, headerName } = useCsrf();
      options.headers = new Headers(options.headers || {});
      options.headers.append(headerName, csrf);
    }
  });
  return {
    provide: { csrfFetch }
  };
});
